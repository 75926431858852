import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { Discord, Github, LinkedIn, Twitter } from "@assets/socials"
import {
  FooterWrap,
  Copyright,
  SocialLinks,
}
from "./footer.styles"



const Footer = () => {
  const animation = useAnimation()
  const [contentRef, inView] = useInView()

  useEffect(() => {
    if (inView) {
      animation.start("visible")
    }
  }, [animation, inView])

  const footerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        ease: "easeIn",
        duration: 0.5,
      },
    },
  }

  return (
    <motion.div
      ref={contentRef}
      variants={footerVariants}
      initial="hidden"
      animate={animation}
    >
      <FooterWrap as="footer">
        <Copyright>© {new Date().getFullYear()} Zenith Ventures Incorporated</Copyright>
        <SocialLinks>
          <Discord />
          <Github />
          <LinkedIn />
          <Twitter />
        </SocialLinks>
      </FooterWrap>
    </motion.div>
  )
}

export default Footer
