import React from "react"
import styled from "styled-components"
import { OutputLabel } from "@components/common/form"
import { SocialData } from "@components/navbar/socialData"

const IconWrap = styled.a`
  color: ${p => p.theme.fillColor};

  svg {
    fill: ${p => p.theme.fillColor};
  }

  svg:hover {
    fill: ${p => p.theme.elevation24};
  }
`



const NavMenuItem = styled.div`
  &.nav-social {
    display: inline;
    /* display: flex;
    flex-direction: column;
    justify-content: start; */
    /* align-items: center; */
    padding: 8px 0px 8px 8px;
    /* list-style: none; */
    /* height: 60px; */
  }

  &.nav-social a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 24px;
    height: 100%;
    /* display: flex; */
    /* align-items: center; */
    padding: 0 16px;
    border-radius: 4px;
  }

  &.nav-social a:hover {
    /* background-color: ${p => p.theme.listItemHover}; */
    color: ${p => p.theme.elevation2};
  }
`

function ThankYouPage({ data }) {
  const { firstName } = data

  return (
    <>
      <OutputLabel label={`Thank you, ${firstName}`} details="example follow up copy notifying registrant of follow up time." />
      <OutputLabel label="check us out on social media" />
      {SocialData.map((item, index) => {
        return (
          <NavMenuItem key={index} className={item.cName}>
            <IconWrap href={item.path} target="_blank" rel="noopener">
              {item.icon}
              {/* <LinkTitle>{item.title}</LinkTitle> */}
            </IconWrap>
          </NavMenuItem>
        )
      })}
    </>
  )
}

export default ThankYouPage
