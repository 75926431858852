import { css } from "styled-components"

// Mono
import PlexMonoBoldWoff from "@assets/fonts/mono/IBMPlexMono-Bold.woff"
import PlexMonoBoldWoff2 from "@assets/fonts/mono/IBMPlexMono-Bold.woff2"

import PlexMonoRegularWoff from "@assets/fonts/mono/IBMPlexMono-Regular.woff"
import PlexMonoRegularWoff2 from "@assets/fonts/mono/IBMPlexMono-Regular.woff2"

// Sans
import PlexSansBoldWoff from "@assets/fonts/sans/IBMPlexSans-Bold.woff"
import PlexSansBoldWoff2 from "@assets/fonts/sans/IBMPlexSans-Bold.woff2"

import PlexSansRegularWoff from "@assets/fonts/sans/IBMPlexSans-Regular.woff"
import PlexSansRegularWoff2 from "@assets/fonts/sans/IBMPlexSans-Regular.woff2"

import WhyteMono from "@assets/fonts/whyte-mono/ABCWhyteMono-Regular.woff"
import WhyteMono2 from "@assets/fonts/whyte-mono/ABCWhyteMono-Regular.woff2"

export const fontFace = css`
  @font-face {
    font-family: "IBM Plex Mono Bold";
    src: url(${PlexMonoBoldWoff}) format("woff"),
      url(${PlexMonoBoldWoff2}) format("woff2");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "IBM Plex Mono Regular";
    src: url(${PlexMonoRegularWoff}) format("woff"),
      url(${PlexMonoRegularWoff2}) format("woff2");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "IBM Plex Sans Bold";
    src: url(${PlexSansBoldWoff}) format("woff"),
      url(${PlexSansBoldWoff2}) format("woff2");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "IBM Plex Sans Regular";
    src: url(${PlexSansRegularWoff}) format("woff"),
      url(${PlexSansRegularWoff2}) format("woff2");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "ABC Whyte Mono";
    src: url(${WhyteMono}) format("woff"),
      url(${WhyteMono2}) format("woff2");
    font-weight: 400;
    font-style: normal;
  }
`
