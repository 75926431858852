import React, { useContext } from "react"
import { Link } from "gatsby"
import { Grid } from "@styles/utilityClasses"
import media from "css-in-js-media"
// import { FirebaseContext } from "@components/Firebase"
// import { Navbar } from "@components"
import styled, { ThemeContext } from "styled-components"
import { Toggle } from "@components/common"
// import { Modal } from "@components"
// import { Button } from "@components/common/form"
// import Form from "@components/common/form/Form"

const HeaderWrap = styled(Grid)`
  margin: 2rem;
  margin-top: 1rem;
  justify-items: center;

  ${media(">desktop")} {
  }
  ${media("<=desktop", ">tablet")} {}

  ${media("<=tablet", ">phone")} {
    margin: 2rem;
  }
`

const Path = styled.path``
const ThemeToggleWrapper = styled.div`
  grid-column: 12;
  ${media(">desktop")} {
  }
  ${media("<=desktop", ">tablet")} {
    grid-column: 6;
  }
  ${media("<=tablet", ">phone")} {
    grid-column: 6 / 8;
    align-content: center;
  }
  ${media("<=phone")} {
    grid-column: 6;
  }
`
const ThemeToggle = styled(Toggle)``

const LogoAnchor = styled.div`

  grid-column: 6 / 8;

  ${media(">desktop")} {
  }
  ${media("<=desktop", ">tablet")} {
    grid-column: 3 / 5;
  }
  ${media("<=tablet", ">phone")} {
    grid-column: 4 / 6;
    align-content: center;
  }
  ${media("<=phone")} {
    grid-column: 4;
  }
`

const Logo = styled.svg`
  height: 24px;
  width: 24px;
  ${Path} {
    fill: ${p => p.theme.fillColor};
  }
`

// const HeaderButton = styled(Button)`
//   grid-column: 11 / 12;
//   ${media(">desktop")} {
//     grid-column: 11 / 12;
//   }
//   ${media("<=desktop", ">tablet")} {
//     grid-column: 5;
//   }
//   ${media("<=tablet", ">phone")} {
//     grid-column: 6;
//   }
//   ${media("<=phone")} {
//     grid-column: 6;
//   }
// `

// const LogoutLink = styled.span`
//   color: white;
//   cursor: pointer;
//   &:hover {
//     text-decoration: underline;
//   }
// `

// const HeaderWrapper = styled.header`
//   margin-bottom: 1.45rem;
//   background-color: ${p => p.theme.primaryColor};
// `

// const HeaderContent = styled.div`
//   margin: 0 auto;
//   max-width: 960px;
//   padding: 1.45rem 1.0875rem;
//   display: flex;
//   > h1 {
//     margin: 0;
//     flex-grow: 1;
//     > a {
//       color: white;
//       text-decoration: none;
//     }
//   }
//   > div {
//     margin: auto 0;
//   }
// `

// const UserInfo = styled.div`
//   grid-column: 12;
//   ${media(">desktop")} {
//   }
//   ${media("<=desktop", ">tablet")} {
//     grid-column: 6;
//   }
//   ${media("<=tablet", ">phone")} {
//     grid-column: 6;
//   }
//   ${media("<=phone")} {
//     grid-column: 4;
//   }
// `

// const LoginLink = styled.div`
//   margin: auto 0;

//   a {
//     color: ${p => p.theme.fillColor};
//   }
// `

// const Divider = styled.span`
//   margin: 0 8px;
//   padding-right: 1px;
//   background: #ddd;
// `

// const AdminLink = styled.span`
//   a {
//     color: white;
//   }
// `

// const RequestAccess = styled(Button)`
//   grid-column: 11;
//   cursor: pointer;

//   ${media(">desktop")} {
//   }
//   ${media("<=desktop", ">tablet")} {
//     grid-column: 5;
//   }
//   ${media("<=tablet", ">phone")} {
//     grid-column: 2;
//     grid-row: 1;
//   }
//   ${media("<=phone")} {
//     grid-column: 2;
//   }
// `

const Header = () => {
  const { id, setTheme } = useContext(ThemeContext)
  // const [showModal, setShowModal] = useState(false)

  // const openModal = () => {
  //   setShowModal(prev => !prev)
  // }

  // // press esc to close modal
  // const handleKeyDown = e => {
  //   if (e.key === "Escape") {
  //     setShowModal(false)
  //   }
  // }

  // const { firebase, user } = useContext(FirebaseContext)
  // console.log(firebase, user)

  // function handleLogoutClick() {
  //   firebase.logout().then(() => navigate("/login"))
  // }

  return (
    <HeaderWrap as="header">
      {/* <NavbarWrapper>
      <Navbar />
      </NavbarWrapper> */}
      <div>
        <a href="https://agency.zenith-research.ca">agency</a>
      </div>
      <LogoAnchor>
        <Link to="/">
          <Logo
            width="24"
            height="33"
            viewBox="0 0 24 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Path
              d="M13.5888 0.614746H8.12057L0 11.0073V16.5203H8.12057L0 26.9132V32.4258H23.0196V28.3245H4.32923L13.5888 16.5203H23.0196V12.419H4.32923L13.5888 0.614746Z"
              fill="white"
            />
          </Logo>
        </Link>
      </LogoAnchor>
      {/* <RequestAccess onClick={openModal} onKeyDown={handleKeyDown}>
        register
      </RequestAccess> */}
      {/* <Modal showModal={showModal} setShowModal={setShowModal}>
        <Form />
      </Modal> */}
      <ThemeToggleWrapper>
        <ThemeToggle isActive={id === "dark"} onToggle={setTheme} />
      </ThemeToggleWrapper>
    </HeaderWrap>
  )
}

export default Header
