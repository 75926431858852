import React, { useState } from "react"
import PropTypes from "prop-types"
import { Header } from "@components"
// import { Footer } from "@components"
import GlobalStyle from "@styles/globalStyles"
import { FirebaseContext, useAuth } from "@components/Firebase"
import { ThemeProvider } from "styled-components"
import DarkTheme from "@styles/themes/dark"
import LightTheme from "@styles/themes/light"
import Footer from "./footer/Footer"

const Layout = ({ children }) => {
  const { user, firebase, loading } = useAuth()
  const [theme, setTheme] = useState(DarkTheme)
  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <ThemeProvider
        theme={{
          ...theme,
          setTheme: () => {
            setTheme(s => (s.id === "dark" ? LightTheme : DarkTheme))
          },
        }}
      >
        <GlobalStyle />
        <Header />
        <main>{children}</main>
        {/* <Footer /> */}
        <Footer />
      </ThemeProvider>
    </FirebaseContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
