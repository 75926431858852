import React from "react"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "./src/styles/globalStyles"
import DarkTheme from "/src/styles/themes/dark"
import Layout from "./src/components/Layout"
// import "firebase/auth"
// import "firebase/firestore"
// import "firebase/functions"

const transitionDelay = 700

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual"
  const currentPosition = getSavedScrollPosition(location)
  window.setTimeout(() => {
    window.scrollTo(...currentPosition)
  }, transitionDelay)
  return false
}

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={DarkTheme}>
    <Layout>
    <GlobalStyle />
    {element}
    </Layout>
  </ThemeProvider>
)
