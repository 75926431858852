const app = import('firebase/compat/app');
const auth = import('firebase/compat/auth');
const database = import('firebase/compat/firestore');
const functions = import('firebase/compat/functions');
const storage = import('firebase/compat/storage');

const loadFirebaseDependencies = Promise.all([app, auth, database, functions, storage]).then(values => {
  return values[0];
});

export default loadFirebaseDependencies;