import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import theme from "styled-theming"
// import colors from '@styles/colors'

export const iconCss = css`
  padding-right: 30px !important;
  .icon-svg {
    position: absolute;
    margin-top: 2px;
    right: 10px;
    top: 50%;
  }

  &:hover {
    .icon-svg {
      opacity: 0.5;
    }
  }
`

export const defaultStyles = css`
  border: 1px solid transparent;
  outline: 0;
  cursor: pointer;
  display: inline-block;
  font-family: var(--heading-text-bold), sans-serif;
  font-size: 1rem;
  /* font-size:${props => props.theme.fontSize}; */
  /* letter-spacing: ${props => props.theme.letterSpacing}; */
  margin: 0;
  border: none;
  /* border-radius: 20px; */
  padding: 0;
  background-color: white;
  /* min-width: 0; */
  text-align: center;
  vertical-align: middle;
  text-transform: lowercase;
  z-index: 1;
`

const primaryStyles = () => {
  return css`
  text-decoration: none;
  padding: 8px 16px;
  background: ${p => p.theme.primaryColor};
  color: ${p => p.theme.bodyTextColor};
  border-radius: 40px;
  border: 1px solid ${p => p.theme.bodyTextColor};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  white-space: nowrap;
  ${props => props.block ? 'display: block; width: 100%;' : ''}
  font-family:  var(--body-text-regular);
  ${props => props.disabled ? 'opacity: 0.5; cursor: not-allowed;' : ''}
  transition: all 0.3s ease-in-out;
  
  &:hover{
    background: ${p => p.theme.elevation2};);
    border: 1px solid ${p => p.theme.elevation2};
  }
    /* background-color: ${p => p.theme.primaryColor};
    color: ${p => p.theme.primaryTextColor};
    &:before {
      content: "";
      background-color: ${p => p.theme.primaryColor};
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease-in-out;
      z-index: -1;
    }

    &:hover {
      background-color: ${p => p.theme.elevation2};
      color: ${p => p.theme.primaryTextColor};
    } */
  `
}

const primary = {
  default: {
    light: primaryStyles({
      bg: "#000",
      text: "#fff",
      textHover: "#000",
    }),
    dark: primaryStyles({
      bg: "#fff",
      text: "#000",
      textHover: "#fff",
    }),
  },
  color: {
    light: primaryStyles({
      bg: "#000",
      text: "#fff",
      textHover: "#000",
    }),
    dark: primaryStyles({
      bg: "#fff",
      text: "#000",
      textHover: "#fff",
    }),
  },
}

const button = {
  defaultStyles,
  primary,
}

export const primaryStylesVariants = theme.variants(
  "mode",
  "variant",
  button.primary
)

const buttonStyles = css`
  ${defaultStyles}
  ${props => {
    if (props.hasIcon) {
      return css`
        ${iconCss}
      `
    } else {
      return css`
        ${primaryStylesVariants}
      `
    }
  }}
`

const ButtonTypeA = styled.a`
  ${buttonStyles}
`

const ButtonLink = styled(({ type, variants, hasIcon, ...props }) => (
  <Link {...props} />
))`
  ${buttonStyles}
`

export { ButtonTypeA, ButtonLink }
