import React from "react"
import styled from "styled-components"

const LinkedInWrap = styled.div`
  padding: 0.25rem;
`

const Path = styled.path``

const Svg = styled.svg`
  ${Path} {
    fill: ${p => p.theme.fillColor};
  }
`

const LinkedIn = () => (
  <LinkedInWrap>
    <a
      href="https://www.linkedin.com/company/zenith-ventures-inc"
      target="_blank"
      aria-label="Follow us on LinkedIn"
    >
      <Svg
        width="24"
        height="21"
        viewBox="0 0 24 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M5.37214 21H0.396429V6.97971H5.37214V21ZM2.88161 5.06721C1.29054 5.06721 0 3.91408 0 2.52189C1.13882e-08 1.85318 0.303597 1.21185 0.844003 0.738991C1.38441 0.266136 2.11736 0.000488281 2.88161 0.000488281C3.64586 0.000488281 4.3788 0.266136 4.91921 0.738991C5.45962 1.21185 5.76321 1.85318 5.76321 2.52189C5.76321 3.91408 4.47214 5.06721 2.88161 5.06721ZM19.0296 21V14.175C19.0296 12.5485 18.9921 10.4625 16.4427 10.4625C13.8557 10.4625 13.4593 12.2297 13.4593 14.0578V21H8.48893V6.97971H13.2611V8.89221H13.3307C13.995 7.79064 15.6177 6.62814 18.0386 6.62814C23.0743 6.62814 24 9.52971 24 13.2985V21H19.0296Z"
          fill="white"
        />
      </Svg>
    </a>
  </LinkedInWrap>
)

export default LinkedIn
