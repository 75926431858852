import React from "react"
import styled from "styled-components"
import {
  // Invest,
  Research,
  Integration,
} from "@components/common/form/steps"

function SecondPage({ data, setData }) {
  const { interests } = data

  if (
    // interests.find(i => i.value === "invest") &&
    interests.find(i => i.value === "integrate") &&
    interests.find(i => i.value === "contribute")
  ) {
    return (
      <InlineFormContainer>
        <Integration data={data} setData={setData} />
        <Research data={data} setData={setData} />
      </InlineFormContainer>
    )
  }

  // if (interests.find(interest => interest.value === "invest")) {
  //   return <Invest data={data} setData={setData} />
  // }
  if (interests.find(interest => interest.value === "integrate")) {
    return (
      <InlineFormContainer>
    <Integration data={data} setData={setData} />
      </InlineFormContainer>
    )
  }

  if (interests.find(interest => interest.value === "contribute")) {
    return ( 
      <InlineFormContainer>
    <Research data={data} setData={setData} />
      </InlineFormContainer>
    )
  }
}

export default SecondPage

const InlineFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  overflow-y: scroll;

`
