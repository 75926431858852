const theme = {
  id: "dark",
  primaryColor: "#100217",
  secondaryColor: "#E5E7EB",
  bodyBackgroundColor: "#100217",
  bodyFontColor: "#fff",
  bodyParagraphColor: "#9CA3AF",
  fillColor: "white",
  linkColor: "#fff",
  borderRight: "rgba(255,255,255, 0.3)",
  listItemHover: "#6B7280",
  modalCloseColor: "#000",
  modalTextColor: "#000",
  elevation1: "#1C0F23",
  elevation2: "#211427",
  elevation8: "#2D2033",
  elevation12: "#312537",
  elevation16: "#34283A",
  elevation24: "#362A3C",
  placeholderColor: "#6E7681",
  modalHighlight: "rgba(159, 135, 255, 0.1)",
  grey3: "#8B949E",

  error: "#DC2626",
}

export default theme
