import React from "react"
import Select from "react-select"
import { useTheme } from "styled-components"

const MultiSelect = ({ ...props }) => {
  const theme = useTheme()

  const colorStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: `${theme.elevation2}`,
      border: `1px solid ${theme.elevation2}`,
      borderRadius: "2px",
      boxShadow: "none",
      fontFamily: "var(--body-text-regular)",
      padding: "4px 8px",

      "&:hover": {
        border: `1px solid ${theme.elevation8}`,
      },
    }),
    option: styles => ({
      ...styles,
      backgroundColor: `${theme.elevation2}`,
      color: `${theme.bodyFontColor}`,
      "&:hover": {
        backgroundColor: `${theme.elevation8}`,
        color: `${theme.bodyFontColor}`,
      },
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: `${theme.elevation2}`,
      borderRadius: "2px",
      height: "40px",
      color: `${theme.bodyFontColor}`,
    }),
    multiValueLabel: styles => ({
      ...styles,
      backgroundColor: `${theme.elevation8}`,
      color: `${theme.bodyFontColor}`,
      fontFamily: "var(--body-text-regular)",
      width: "100%",
      boxSizing: "border-box",
      "&:focus": {
        backgroundColor: `${theme.modalHighlight}`,
        color: `${theme.bodyFontColor}`,
      },
    }),
    multiValueRemove: styles => ({
      ...styles,
      backgroundColor: `${theme.elevation8}`,
      color: `${theme.bodyFontColor}`,
      fontFamily: "var(--body-text-regular)",
      "&:hover": {
        backgroundColor: `${theme.error}`,
        color: `${theme.bodyFontColor}`,
      },
      "&:focus": {
        backgroundColor: `${theme.modalHighlight}`,
        color: `${theme.bodyFontColor}`,
      },
    }),
    menuList: styles => ({
      ...styles,
      backgroundColor: `${theme.elevation2}`,
      margin: "0",
      padding: "0",
    }),
    menu: styles => ({
      ...styles,
      backgroundColor: `${theme.elevation2}`,
      margin: "0",
      fontFamily: "var(--body-text-regular)",
    }),
    input: styles => ({
      ...styles,
      backgroundColor: `${theme.elevation2}`,
      color: `${theme.bodyFontColor}`,

      "&:focus": {
        backgroundColor: `${theme.elevation2}`,
        color: `${theme.bodyFontColor}`,
      },
    }),
    placeholder: styles => ({
      ...styles,
      color: `${theme.placeholderColor}`,
    }),

    singleValue: styles => ({
      ...styles,
      color: `${theme.bodyFontColor}`,
    }),
    valueContainer: styles => ({
      ...styles,
      color: `${theme.placeholderColor}`,
    }),
  }

  return <Select styles={colorStyles} {...props} />
}

export default MultiSelect
