import React from "react"
import styled from "styled-components"
import Select from "react-select"
import { Input, Label, OutputLabel } from "@components/common/form"
import { customStyles } from "@styles/select/colourOptions"

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
`

export default function Invest({ data, setData }) {
  return (
    <>
      <OutputLabel label="access investment opportunities" />
      <FormWrap>
        <Label htmlFor="access">
          access *
          <Select
            name="access"
            // placeholder="select sectors"
            isClearable={true}
            closeOnSelect={false}
            styles={customStyles}
            options={[
              { value: "partner", label: "partner on proprietary research" },
              {
                value: "access",
                label: "access screened investment opportunities",
              },
            ]}
            onChange={e => {
              setData({
                ...data,
                access: e,
              })
            }}
            value={data.access}
          />
        </Label>

        <Label htmlFor="select-sectors">
          sector(s) of interest *
          <Select
            options={[
              { value: "automation", label: "automation" },
              { value: "energy", label: "energy" },
              { value: "materials", label: "materials" },
              { value: "industrials", label: "industrials" },
              { value: "utilities", label: "utilities" },
              { value: "healthcare", label: "healthcare" },
              { value: "financials", label: "financials" },
              {
                value: "consumerDiscretionary",
                label: "consumer discretionary",
              },
              { value: "consumerStaples", label: "consumer staples" },
              {
                value: "informationTechnology",
                label: "information technology(it)",
              },
              {
                value: "communicationServices",
                label: "communication services",
              },
              { value: "realestate", label: "real estate" },
              { value: "mining", label: "mining" },
              { value: "entertainment", label: "entertainment" },
              { value: "forestry", label: "forestry" },
              { value: "marketing", label: "marketing" },
              { value: "food-tech", label: "food-tech" },
              { value: "vacationRentals", label: "vacation rentals" },
            ]}
            styles={customStyles}
            name="select-sectors"
            placeholder="select sectors"
            isClearable={true}
            closeOnSelect={false}
            isMulti={true}
            onChange={e => {
              console.log(e)
              setData({
                ...data,
                sectors: e,
              })
            }}
            value={data.sectors}
            required
          />
        </Label>
        <Label htmlFor="add area of interest">
          add sector of interest
          <Input
            name="add sector"
            placeholder="add sector"
            onChange={e => {
              setData({
                ...data,
                addSector: e.target.value,
              })
            }}
            value={data.addSector}
          />
        </Label>
      </FormWrap>
    </>
  )
}
