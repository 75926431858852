const theme = {
  id: "light",
  primaryColor: "#F9FAFB",
  secondaryColor: "#000",
  bodyBackgroundColor: "white",
  bodyFontColor: "black",
  bodyParagraphColor: "#6B7280",
  fillColor: "black",
  linkColor: "#000",
  borderRight: "rgba(0, 0, 0, .9)",
  listItemHover: "#6B7280",
  modalCloseColor: "#fff",
  modalTextColor: "#fff",
  elevation1: "#F9F5FE",
  elevation2: "#E5E7EB",
  elevation8: "#fff",
  elevation12: "#9D59EF",
  elevation16: "#883AE1",
  elevation24: "#512082",
  placeholderColor: "#6E7681",
  modalHighlight: "rgba(159, 135, 255, 0.1)",
  grey3: "#8B949E",

  error: "#DC2626",
}

export default theme
