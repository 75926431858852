const firebaseConfig = {
  apiKey: "AIzaSyBan0maPsbK3Kfw7V9LfZuJMjtnqH9yKJg",
  authDomain: "zenith-a433e.firebaseapp.com",
  projectId: "zenith-a433e",
  storageBucket: "zenith-a433e.appspot.com",
  messagingSenderId: "912733147931",
  appId: "1:912733147931:web:7a68aae8c7914111f379be",
  measurementId: "G-8NJ2SD8PL9",
}

export default firebaseConfig
