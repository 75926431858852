exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bbl-consultation-js": () => import("./../../../src/pages/bbl-consultation.js" /* webpackChunkName: "component---src-pages-bbl-consultation-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-investor-connect-js": () => import("./../../../src/pages/investor-connect.js" /* webpackChunkName: "component---src-pages-investor-connect-js" */),
  "component---src-pages-letter-from-the-ceo-js": () => import("./../../../src/pages/letter-from-the-ceo.js" /* webpackChunkName: "component---src-pages-letter-from-the-ceo-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */)
}

