import React from "react"
import styled from "styled-components"
import {
  // ErrorMessage,
  Label,
  OutputLabel,
  TextArea,
  MultiSelect
} from "@components/common/form"

import { FaInfo } from "react-icons/fa"

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

function Integration({ data, setData }) {
  return (
    <>
      <OutputLabel 
      label="bespoke integration"
      details="we deliver bespoke process automation, and data-collection pipelines
      that improve operating margins and business scalability." 
      
      />
      <FormWrap>
        <Label htmlFor="integrate">
          integrate *
          <MultiSelect
            name="integrate"
            // placeholder="select sectors"
            isClearable={true}
            closeOnSelect={false}
            isSearchable={false}
            // components={{ Option }}
            options={[
              {
                value: "dataCollection",
                label: (
                  <>
                    <span style={{ paddingRight: "5px" }}>data collection</span>
                    <FaInfo title={"implement automated and scalable data collection pipelines with zenith business process automation"} />
                  </>
                )
              },
              {
                value: "processAutomation",
                label: (
                  <>
                    <span style={{ paddingRight: "5px" }}>business process automation</span>
                    <FaInfo title={"improve margins and operational scalability by automating core business processes with zenith"} />
                  </>
                )
              },
              { value: "partner",
              label: (
                <>
                  <span style={{ paddingRight: "5px" }}>partner on proprietary research</span>
                  <FaInfo title={"invest and partner on our open research projects and receive, scope control, ip and distribution rights for the project’s analytics and decision making pipeline"} />
                </>
              )
             },
            ]}
            onChange={e => {
              setData({
                ...data,
                integrate: e,
              })
            }}
            value={data.integrate}
          />
        </Label>
        <Label htmlFor="tell us your needs">
          tell us your needs
          <TextArea
            name="tell us your needs"
            placeholder="tell us your needs"
            onChange={e => {
              setData({
                ...data,
                needs: e.target.value,
              })
            }}
            value={data.needs}
          />
        </Label>
      </FormWrap>
    </>
  )
}

export default Integration
