import React, { useEffect } from "react"
import styled from "styled-components"
import { MdClose } from "react-icons/md"
// import Form from "@components/common/form/Form"
import { motion } from "framer-motion"
import media from "css-in-js-media"

const Modal = ({ showModal, setShowModal, children }) => {
  // lock scroll position when modal is open

  // currently not supported in firefox
  // https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter

  useEffect(() => {
    const handleScroll = () => {
      if (showModal) {
        document.body.style.overflow = "hidden"
        document.getElementById("modal").style.backdropFilter = "blur(4px)"
      } else {
        document.body.style.overflow = "auto"
      }
    }
    handleScroll()
  }, [showModal])

  return (
    <>
      {showModal ? (
        <ModalWrapper showModal={showModal} className="modal-wrap" id="modal">
          <ModalContent
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="modal-content"
          >
            <ModalHeader>
              <ModalCloseButton onClick={() => setShowModal(prev => !prev)} />
            </ModalHeader>
            {/* <Form /> */}
            {children}
          </ModalContent>
        </ModalWrapper>
      ) : null}
    </>
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1000;
`

const ModalContent = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${p => p.theme.primaryColor};
  border: 1px solid ${p => p.theme.elevation24};
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 1rem;


  //add media queries for mobile and tablet
  

  ${media("<tablet", ">phone")} {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    overflow-y: auto;
  }
  ${media("<=phone")} {}

  /* min-width: 600px; */
  /* margin-bottom: 48px; */
`

const ModalHeader = styled.div``

const ModalCloseButton = styled(MdClose)`
  font-size: 1.5rem;
  color: ${p => p.theme.bodyFontColor};
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export default Modal
