import React from "react"
import PropTypes from "prop-types"

import { Icon } from "@components"

import { ButtonTypeA, ButtonLink } from "./button.styles"

const renderedIcon = (hasIcon, icon) => {
  if (hasIcon) {
    return <Icon icon={icon} />
  }
}

const Button = ({
  to,
  children,
  variant,
  type,
  icon,
  hasIcon,
  linksOut,
  isEmail,
}) => {
  if (linksOut || isEmail) {
    return (
      <ButtonTypeA
        to={to}
        href={to}
        type={type}
        variant={variant}
        target={linksOut ? "_blank" : ""}
        rel={linksOut ? "noopener noreferrer" : ""}
        hasIcon={hasIcon}
      >
        {children}
        {renderedIcon(hasIcon, icon)}
      </ButtonTypeA>
    )
  } else {
    return (
      <ButtonLink to={to} variant={variant} type={type} hasIcon={hasIcon}>
        {children}
        {renderedIcon(hasIcon, icon)}
      </ButtonLink>
    )
  }
}

Button.propTypes = {
  variant: PropTypes.oneOf(["default", "color"]),
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  hasIcon: PropTypes.bool,
  icon: PropTypes.string,
  linksOut: PropTypes.bool,
  isEmail: PropTypes.bool,
}

Button.defaultProps = {
  variant: "default",
  type: "button",
  hasIcon: false,
  icon: "arrow-right",
  linksOut: false,
  isEmail: false,
}

export default Button
