import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Break = styled.div`
  padding-right: 10px;
  padding-left: 10px;
`

const History = styled.div`
  box-shadow: none;
  color: ${p => p.theme.bodyFontColor};
  text-decoration: none;

  &:hover {
    color: ${p => p.theme.bodyParagraphColor};
  }
`

const Flex = styled.div`
  display: flex;
  align-items: baseline;
`

const Current = styled.p`
  font-weight: bold;
  color: ${p => p.theme.bodyParagraphColor};
  user-select: none;
`



const Breadcrumbs = ({ to, page, current }) => {

  return (
    <Flex>
      <Flex>
        <History>
          <Link to={to}>
          {page}
          </Link>
          </History>
        <Break>/</Break> 
        <Current>{current}</Current>
      </Flex>
    </Flex>
  )
}

export default Breadcrumbs
