import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { fontFace } from "./fontFace"
import media from "css-in-js-media"


export const typography = css`

    ${fontFace}

    :root {
        --primary-color: #DFECE0;
        --background-color: #0C1212;

        --body-text-regular: "ABC Whyte Mono", sans-serif;
        --body-text-bold: "ABC Whyte Mono", sans-serif;
        --heading-text-regular: "ABC Whyte Mono", sans-serif;
        --heading-text-bold: "ABC Whyte Mono", sans-serif;
    }

    h1 {
        font-family: var(--heading-text-bold), sans-serif;
        font-size: 1.2rem;
        /* color: var(--primary-color); */
    }

    h2,h3 {
        font-family: var(--body-text-regular), sans-serif;
        /* color: var(--primary-color); */
        font-size: 1rem;
    }

    p, label{
        font-family: var(--body-text-regular), sans-serif;
        /* color: var(--primary-color); */
        /* margin-inline-end: auto; */
    }

    label {
        font-size: 0.8rem;
        text-align: left !important;
    }

    a, button {
        font-family: var(--body-text-regular), sans-serif;
        color: var(--string);
        text-decoration: none;
    }

    /* span, input, textarea, select, label, button {
        font-family: var(--body-text-regular), sans-serif;
        /* color: var(--string) */
    } */

    



`

export const LargeDisplay = styled.h1`
  font-size: 3.563rem;
  line-height: 64px;
  /* line-height: 1.1; */
  letter-spacing: -0.25px;
  ${media(">desktop")} {

  }
  ${media("<=desktop", ">tablet")} {
    font-size: 2.813rem;
    line-height: 52px;
    letter-spacing: -0.25px;
  }

  }
  ${media("<=tablet", ">phone")} {
    font-size: 2.063rem;
    line-height: 44px;
  }
  ${media("<=phone")} {
  }
`

export const MediumDisplay = styled.h1`
  font-size: 2.813rem;
  line-height: 52px;


  ${media(">desktop")} {

  }
  ${media("<=desktop", ">tablet")} {
    font-size: 2.813rem;
    line-height: 52px;
    letter-spacing: -0.25px;
  }
  ${media("<=tablet", ">phone")} {
    font-size: 2.063rem;
    line-height: 44px;
  }
  ${media("<=phone")} {
    
  }

`

export const SmallDisplay = styled.h1`
  font-size: 2.063rem;
  line-height: 44px;
`

export const LargeHeading = styled(motion.h2)`
  font-size: 2rem;
  line-height: 40px;
`

export const MediumHeading = styled.h2`
  font-size: 1.75rem;
  line-height: 36px;
`

export const SmallHeading = styled.h2`
  font-size: 1.5rem;
  line-height: 32px;
`

export const LargeTitle = styled.h3`
  font-size: 1.375rem;
  line-height: 28px;
`

export const MediumTitle = styled.h3`
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.1px;
`

export const SmallTitle = styled.h3`
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.1px;
`

export const LargeLabel = styled.p`
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.1px;
`

export const MediumLabel = styled.p`
  font-size: 0.75rem;
  line-height: 16px;
  letter-spacing: 0.5px;
`

export const SmallLabel = styled.p`
  font-size: 0.688rem;
  line-height: 16px;
  letter-spacing: 0.5px;
`

export const LargeBody = styled.p`
font-size: 1rem;
line-height: 24px;
letter-spacing: 0.5px;
`

export const MediumBody = styled.p`
font-size: 0.875rem;
line-height: 20px;
letter-spacing: 0.25px;
`

export const SmallBody = styled.p`
font-size: 0.75rem;
line-height: 16px;
letter-spacing: 0.4px;
`

export default typography