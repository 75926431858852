import styled from "styled-components"
import { Grid } from "@styles/utilityClasses"
import media from "css-in-js-media"

const FooterWrap = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Copyright = styled.p`
  /* color: ${p => p.theme.secondaryColor}; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
`

const SocialLinks = styled.div`
  display: flex;
  grid-column: 12;
  justify-self: end;
  justify-content: center;
  align-items: center;
  grid-row: 1;

  ${media(">desktop")} {
    grid-column: 11;
  }
  ${media("<=desktop", ">tablet")} {
    grid-column: 5;
    /* justify-self: start; */
  }
  ${media("<=tablet", ">phone")} {
    grid-column: 6;
    /* justify-self: start; */
  }
  ${media("<=phone")} {
    grid-column: 6;
    /* justify-self: start; */
  }
`

export { FooterWrap, Copyright, SocialLinks }
