import React from "react"
import styled from "styled-components"

// create a tooltip component that renders a tooltip when hovered

const ToolTip = styled.div`
  /* position: absolute;
  top: -10px;
  left: -10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  font-family: var(--body-text-regular);
  color: var(--primary-color);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    z-index: -1;
  }
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    z-index: -1;
  }
  &:hover {
    opacity: 1;
  } */
`

const ToolTipTitle = styled.h1`
  font-size: 12px;
  color: ${p => p.theme.bodyFontColor};
  margin: 0 0.25rem;
  text-align: left;
`

const ToolTipDescription = styled.p`
  font-size: 12px;
  color: ${p => p.theme.bodyFontColor};
  margin: 0 0.25rem;
  text-align: left;
`

export function Tooltip({ title, description }) {
  return (
    <ToolTip>
      <ToolTipTitle>{title}</ToolTipTitle>
      <ToolTipDescription>{description}</ToolTipDescription>
    </ToolTip>
  )
}

// import React from "react"
// import styled from "styled-components"
// import PropTypes from "prop-types"

// const ToolTipWrapper = styled.span`
//   visibility: hidden;
//   width: 120px;
//   background-color: black;
//   color: #fff;
//   text-align: center;
//   border-radius: 6px;
//   padding: 5px 0;
//   position: absolute;
//   z-index: 1;
//   bottom: 150%;
//   left: 50%;
//   margin-left: -60px;

//   &::after {
//     content: "";
//     position: absolute;
//     top: 100%;
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: black transparent transparent transparent;
//   }

//     &:hover {
//         visibility: visible;
//     }
// `

// const ToolTipTitle = styled.h1`
//   font-size: 12px;
//   color: ${p => p.theme.bodyFontColor};
//   margin: 0 0.25rem;
//   text-align: left;
// `

// const ToolTipDescription = styled.p`
//   font-size: 12px;
//   color: ${p => p.theme.bodyFontColor};
//   margin: 0 0.25rem;
//     text-align: left;
// `

// const ToolTip = ({ title, description }) => {
//   return (
//     <ToolTipWrapper>
//       <ToolTipTitle>{title}</ToolTipTitle>
//       <ToolTipDescription>{description}</ToolTipDescription>
//     </ToolTipWrapper>
//   )
// }

// export default ToolTip

// ToolTip.propTypes = {
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
// }

// ToolTip.defaultProps = {
//   title: "",
//   description: "",
// }
