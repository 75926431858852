import React from "react"
// import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { useStaticQuery, graphql } from "gatsby"

// const AvatarQuery = () => {
//     const data = useStaticQuery(graphql`
//         query {

const Avatar = ({ image, alt, size }) => {
  const imageData = getImage(image)
  const { fluid } = imageData

  return (
    <GatsbyImage
      fluid={fluid}
      alt={alt}
      imgStyle={{
        objectFit: "cover",
        objectPosition: "center",
        width: size,
        height: size,
      }}
    />
  )
}

export default Avatar
