import React from "react"
import styled from "styled-components"
import { Input, Label, OutputLabel, MultiSelect } from "@components/common/form"
import { ImInfo } from "react-icons/im"




function FirstPage({ data, setData }) {

const MouseEnter = () => {
  // document.body.style.cursor = 'pointer';
  document.getElementById("info-1").style.cursor = "help";
  document.getElementById("info-2").style.cursor = "help";
}

  return (
    <>
      <OutputLabel
        label="request access"
        details="join our growing waitlist today and our team will reach out to you as soon as possible."
      />
      <FormWrap>
        <FormTopRow>
          <FlexItem>
            <Label htmlFor="first name">first name *</Label>
            <Input
              name="first name"
              placeholder="first name"
              onChange={e => {
                setData({
                  ...data,
                  firstName: e.target.value,
                })
              }}
              value={data.firstName}
              required
            />
          </FlexItem>
          <FlexItem>
            <Label htmlFor="last name">last name *</Label>
            <Input
              name="last name"
              placeholder="last name"
              onChange={e => {
                setData({
                  ...data,
                  lastName: e.target.value,
                })
              }}
              value={data.lastName}
              required
            />
          </FlexItem>
        </FormTopRow>
        <FormBottomRow>
          <FlexItem>
            <Label htmlFor="email">email *</Label>
            <Input
              placeholder="email"
              type="email"
              onChange={e => {
                setData({
                  ...data,
                  email: e.target.value,
                })
              }}
              value={data.email}
              required
            />
          </FlexItem>
          <FlexItem>
            <Label htmlFor="confirm email">confirm email *</Label>
            <Input
              name="confirm email"
              placeholder="confirm email"
              onChange={e => {
                setData({
                  ...data,
                  confirmEmail: e.target.value,
                })
              }}
              value={data.confirmEmail}
              required
            />
          </FlexItem>
        </FormBottomRow>
        <Block>
          <Label htmlFor="select-interest">select interest(s) *</Label>
          <MultiSelect
            options={[
              { value: "contribute", label: (
                <>
                  <span style={{ paddingRight: "5px" }}>contribute to research</span>
                  <ImInfo id="info-1"  title={"contribute option"}
                         onMouseEnter={MouseEnter}
                  />
                </>
              ) },
              { value: "integrate", label: (
                <>
                  <span style={{ paddingRight: "5px" }}>integrate research</span>
                  <ImInfo id="info-2" title={"Improve margins and operational scalability by automating core business processes with zenith"}
                    onMouseEnter={MouseEnter}
                    style={{ textAlign: "center" }}
                  />
                </>
              )},
            ]}
            name="select-interest"
            placeholder="interests..."
            isClearable={true}
            isSearchable={false}
            closeOnSelect={false}
            isMulti={true}
            onChange={e => {
              console.log(e)
              setData({
                ...data,
                interests: e,
              })
            }}
            value={data.interests}
            required
          />
        </Block>
      </FormWrap>
    </>
  )
}

export default FirstPage

const FormTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

const FormBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 24px; */
  gap: 24px;
`

const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
`

const Block = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  /* padding: 0 1rem; */
`

export const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`
