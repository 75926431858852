import { css } from "styled-components"

export const reset = css`
  /* Box sizing reset */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests that default styles will be removed */

  ul[role="list"],
  ol[role="list"] {
    list-style: none;
  }

  /* Set core root defaults */

  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */

  body {
    /* min-height: 100vh; */
    text-rendering: optimizeSpeed;
    line-height: 2.2em;
  }

  /* A elements that don't have a class get default styles  */

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */

  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */

  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`

export default reset
