import styled from "styled-components"

export const TextArea = styled.textarea`
border: 1px solid ${p => p.theme.elevation2};
border-radius: 2px;
background-color: ${p => p.theme.elevation2};
color: ${p => p.theme.bodyFontColor};
font-family: var(--body-text-regular);
font-size: 14px;
padding: 4px 16px;
margin-bottom: 8px;
width: 100%;
box-sizing: border-box;


&::placeholder {
    color: ${p => p.theme.placeholderColor};
}


`