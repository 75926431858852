import styled from "styled-components"
import media from "css-in-js-media"
// import { Link } from "gatsby"

// const utilityClasses = css``

const Flex = styled.div`
  display: flex;
  gap: var(--gap, 1rem);
`

const Grid = styled.div`
  ${media(">desktop")} {
    display: grid;
    margin-inline: auto;
    grid-template-rows: max-content;
    grid-template-columns: repeat(12, 1fr);

    gap: ${props => (props.gapDefault ? props.gapDefault : "2rem 1rem")};
    /* margin: ${props => (props.marginDefault ? props.marginDefault : "7rem")}; */
  }
  ${media("<=desktop", ">tablet")} {
    display: grid;
    margin-inline: auto;
    grid-template-rows: max-content;
    grid-template-columns: repeat(6, 1fr);
    gap: ${props => (props.gapMedium ? props.gapMedium : "1rem 1rem")};
    margin: ${props => (props.marginMedium ? props.marginMedium : "2rem")};
  }
  ${media("<=tablet", ">phone")} {
    display: grid;
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: ${props => (props.gapSmall ? props.small : "1rem 1rem")};
    margin: ${props => (props.marginSmall ? props.marginSmall : 0)};
  }
  ${media("<=phone")} {
    display: grid;
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: ${props => (props.gapSmall ? props.small : "1rem 1rem")};
    margin: ${props => (props.marginSmall ? props.marginSmall : 0)};
  }
`

const Container = styled.div`
  /* width: min(100% - 2rem, 1600px);
  margin-inline: auto;
  max-width: 100rem; */
`

const ScreenReaderOnly = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`

const HorizontalRule = styled.span`
  width: 100%;
  display: block;
  border-top: 1px solid ${(props => props.theme.colours.primary)};
  margin: 1rem 0;
  position: relative;
  z-index: 100;
`

// const Button = styled(Link)`
//   border: none;
//   border-radius: 2000px;
//   padding: 0 1rem;
//   background-color: ${(props => props.theme.colours.primary)};
//   color: ${(props => props.theme.colours.secondary)};
//   text-align: center;
// `

const Section = styled.section`
  height: 100vh;

  ${media(">desktop")} {
    height: 100vh;
  }
  ${media("<=desktop", ">tablet")} {
  }
  ${media("<=tablet", ">phone")} {
    height: auto;
  }
  ${media("<=phone")} {
  }
`

const Spacer = styled.div`
  height: 6rem;

  ${media(">desktop")} {
    height: 6rem;
  }
  ${media("<=desktop", ">tablet")} {
    height: 4rem;
  }
  ${media("<=tablet", ">phone")} {
    height: 3rem;
  }
  ${media("<=phone")} {
    height: 1.5rem;
  }
`

export {
  // utilityClasses,
  Flex,
  Grid,
  Container,
  ScreenReaderOnly,
  HorizontalRule,
  // Button,
  Section,
  Spacer,
}
