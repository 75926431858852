import React, { useState, useContext } from "react"
import { FirebaseContext } from "@components/Firebase"
import styled from "styled-components"
import FirstPage from "./steps/First"
import SecondPage from "./steps/Second/SecondPage"
import ConfirmationPage from "./steps/Third/Confirmation"
import ThankYouPage from "./steps/ThankYou/ThankYou"
import { Button } from "@components/common/form"
import media from "css-in-js-media"

export default function Form() {
  const [page, setPage] = useState(0)
  const { firebase } = useContext(FirebaseContext)
  console.log("firebase", firebase)

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    interests: null,
    access: null,
    // addSector: "",
    contribute: null,
    message: "",
    // sectors: null,
    needs: "",
  })

  // const [errorMessage, setErrorMessage] = useState("")

  // const submitForm = async (e) => {
  //   e.preventDefault();

  //   if (firebase) {
  //     try {
  //       const db = firebase.firestore();
  //       const docRef = db.collection("registrant").doc();

  //       await docRef.set(
  //         {
  //           firstName: data.firstName,
  //           lastName: data.lastName,
  //           email: data.email,
  //           confirmEmail: data.confirmEmail,
  //           interests: data.interests,
  //           access: data.access,
  //           // addSector: data.addSector,
  //           contribute: data.contribute,
  //           message: data.message,
  //           // sectors: data.sectors,
  //           needs: data.needs,
  //         },
  //         { merge: true }
  //       );
  //       alert("Successfully added to Firestore!");
  //     } catch (error) {
  //       alert(`An error occured: ${error}`);
  //       console.log("error", error);
  //     }
  //   }
  // };

  // function handleRegistrantSubmit(e) {
  //   e.preventDefault()
  //   console.log("handleRegistrantSubmit", data)
  //   firebase
  //     .createRegistrant({
  //       firstName: data.firstName,
  //       lastName: data.lastName,
  //       email: data.email,
  //       confirmEmail: data.confirmEmail,
  //       interests: data.interests,
  //       access: data.access,
  //       // addSector: data.addSector,
  //       contribute: data.contribute,
  //       message: data.message,
  //       // sectors: data.sectors,
  //       needs: data.needs,
  //     })
  //     .then(
  //       () => {
  //         setPage(page + 1)
  //       },
  //       error => {
  //         setErrorMessage(error.message)
  //       }
  //     )
  // }

  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return <FirstPage data={data} setData={setData} />
      case 1:
        return <SecondPage data={data} setData={setData} />
      case 2:
        return <ConfirmationPage data={data} setData={setData} />
      case 3:
        return <ThankYouPage data={data} setData={setData} />
      default:
        return <FirstPage data={data} setData={setData} />
    }
  }

  const handleSubmit = async (e) => {
    // setPage(page + 1)
    e.preventDefault()

    if (page === 0) {
      if (data.firstName === "") {
        return alert("Please enter your name")
      }
      if (data.email === "") {
        return alert("Please enter your email")
      }
      if (!data.email.includes("@")) {
        return alert("Please enter a valid email")
      }
      if (data.interests === null) {
        return alert("Please select at least one interest")
      }

      if (data.confirmEmail !== data.email) {
        return alert("emails do not match")
      } else {
        setPage(page + 1)
      }
    }

    if (page === 1) {
      // if (data.interests.find(i => i.value === "invest")) {
      //   if (data.access === null) {
      //     return alert("Please select at least one investment option")
      //   }
      //   if (data.sectors === null) {
      //     return alert("Please select at least one sector of interest")
      //   } else {
      //     setPage(page + 1)
      //     console.log(data)
      //   }
      // }
      if (data.interests.find(i => i.value === "integrate")) {
        if (data.integrate === null) {
          return alert("Please select at least one integration option")
        } else {
          setPage(page + 1)
        }
      }
      if (data.interests.find(i => i.value === "contribute")) {
        if (data.contribute === null) {
          return alert("Please select at least one contribution option")
        } else {
          setPage(page + 1)
        }
      }
    }

    // add integration and research error handling

    if (page === 2) {
      e.preventDefault()
      try {
        await firebase.createRegistrant({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          confirmEmail: data.confirmEmail,
          interests: data.interests,
          access: data.access,
          // addSector: data.addSector,
          contribute: data.contribute,
          message: data.message,
          // sectors: data.sectors,
          needs: data.needs,
        })
        setPage(page + 1)
      } catch (error) {
        // setErrorMessage(error.message)
      }
  }

}

  return (
    <>
      <FormWrapper>
        <Logo
          width="24"
          height="33"
          viewBox="0 0 24 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M13.5888 0.614746H8.12057L0 11.0073V16.5203H8.12057L0 26.9132V32.4258H23.0196V28.3245H4.32923L13.5888 16.5203H23.0196V12.419H4.32923L13.5888 0.614746Z"
            fill="white"
          />
        </Logo>
        {conditionalComponent()}

        <ButtonWrapper>
          {page > 0 && (
            <PreviousButton onClick={() => setPage(page - 1)}>
              <ArrowLeft
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <Path
                  d="M12.6668 8.00016H3.3335M3.3335 8.00016L8.00016 12.6668M3.3335 8.00016L8.00016 3.3335"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </ArrowLeft>
              back
            </PreviousButton>
          )}

          <NextButton type="submit" onClick={handleSubmit}>
            {page === 0 || page === 1 ? "continue registration" : ""}
            {page === 2 ? "complete registration" : null}
            <ArrowRight
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M3.33334 7.99967H12.6667M12.6667 7.99967L8.00001 3.33301M12.6667 7.99967L8.00001 12.6663"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </ArrowRight>
          </NextButton>
        </ButtonWrapper>
      </FormWrapper>
    </>
  )
}

const FormWrapper = styled.div`
  padding: 48px 56px;
  overflow-y: scroll;

  ${media("<=tablet", ">=phone")} {
    padding: 0;
  }
`

const ButtonWrapper = styled.div`
  /* margin-top: 20px; */
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const Path = styled.path``

const ArrowRight = styled.svg`
  height: 16px;
  width: 16px;
  margin: 0.5rem;
  vertical-align: middle;

  ${Path} {
    stroke: ${p => p.theme.fillColor};
  }
`

const ArrowLeft = styled.svg`
  height: 16px;
  width: 16px;
  margin: 0.5rem;
  vertical-align: middle;
  ${Path} {
    stroke: ${p => p.theme.fillColor};
  }
`

const PreviousButton = styled(Button)`
  margin-top: 1rem;
  justify-content: flex-start;
  margin-right: 1rem;
`

const NextButton = styled(Button)`
  margin-top: 1rem;
  justify-content: flex-end;
`

const Logo = styled.svg`
  height: 24px;
  width: 24px;
  order: 1;
  /* margin: 0.5rem; */
  /* vertical-align: middle; */
  ${Path} {
    fill: ${p => p.theme.fillColor};
  }
`
