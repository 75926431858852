import firebaseConfig from "./config"
import app from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/storage"

app.initializeApp(firebaseConfig)

class Firebase {
  constructor() {
    if (!firebaseInstance) {
      this.auth = app.auth()
      this.db = app.firestore()
      this.functions = app.functions()
      this.storage = app.storage()
    }
  }

  getUserProfile({ userId, onSnapshot }) {
    return this.db
      .collection("publicProfiles")
      .where("userId", "==", userId)
      .limit(1)
      .onSnapshot(onSnapshot)
  }

  async createRegistrant({ firstName, lastName, email, confirmEmail, interests, access, contribute, message, needs }) {
    const postRegistrantCallable = this.functions.httpsCallable("createRegistrant")
    return postRegistrantCallable({
      firstName,
      lastName,
      email,
      confirmEmail,
      interests,
      access,
      contribute,
      message,
      needs,
    })
  }

  // register function

  async register({ email, password, username }) {
    const newUser = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    )
    return this.db.collection("publicProfiles").doc(username).set({
      userId: newUser.user.uid,
    })
  }


  async login({ email, password }) {
    return this.auth.signInWithEmailAndPassword(email, password)
  }

  async logout() {
    await this.auth.signOut()
  }
}

let firebaseInstance

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase(app)
    return firebaseInstance
  } else if (firebaseInstance) {
    return firebaseInstance
  } else {
    return null
  }
}

// var db = app.firestore();

// export { db };

export default getFirebaseInstance
