import React from "react";
import styled from "styled-components";
import { MediumBody } from "@styles/typography";

const OutputLabelWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
color: ${p => p.theme.bodyFontColor};
`

const Title = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  color: ${p => p.theme.bodyFontColor};
  /* margin: 0 1rem; */
`

const Text = styled(MediumBody)`
  text-align: center;
  font-size: 1rem;
  color: ${p => p.theme.grey3};
  margin: 1rem auto;
  max-width: 500px;
  padding: 0 1rem;
`

const OutputLabel = ({ label, details }) => {
  return (
    <OutputLabelWrapper>
      <Title>{label}</Title>
      <Text>{details}</Text>
    </OutputLabelWrapper>
  );
}

export default OutputLabel
