import React from 'react';
import * as FaIcons from 'react-icons/fa';


export const SocialData = [
    {
        title: 'discord',
        path: 'https://discord.gg',
        icon: <FaIcons.FaDiscord/>,
        cName: 'nav-social'
    },
    {
        title: 'twitter',
        path: 'https://twitter.com/',
        icon: <FaIcons.FaTwitter />,
        cName: 'nav-social'
    },
    {
        title: 'linkedin',
        path: 'https://www.linkedin.com/',
        icon: <FaIcons.FaLinkedin />,
        cName: 'nav-social'
    },
    {
        title: 'github',
        path: 'https://github.com',
        icon: <FaIcons.FaGithub />,
        cName: 'nav-social'
    },
];