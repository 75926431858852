import { createGlobalStyle } from "styled-components"
import { reset } from "./reset"
import { typography } from "./typography"

const GlobalStyle = createGlobalStyle`

*, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
  }

${reset}
${typography}

:root {
    /* --primary-color: #DFECE0; */
    --background-color: #0F0F10;
    /* --matija-test-color: #55b5db;
    --matija-test-color-2: #84a74a; */
    --string: #92d192;
    --function: #62cfcf;
    --punctuation: #d5d8df;
    --yellow: #FED716;

    --primary-50: #F9F5FE;
    --primary-100: #F1E8FD;
    --primary-200: #E6D6FC;
    --primary-300: #D2B5F9;
    --primary-400: #B786F5;
    --primary-500: #9D59EF;
    --primary-600: #883AE1;
    --primary-700: #7429C6;
    --primary-800: #6326A2;
    --primary-900: #512082;


    --secondary-50: #F5F5FF;
    --secondary-100: #E1E1FE;
    --secondary-200: #C3C4FE;
    --secondary-300: #9B9DFD;
    --secondary-400: #4B4EFC;
    --secondary-500: #4B4EFC;
    --secondary-600: #1E22FB;
    --secondary-700: #0408E7;
    --secondary-800: #0306BA;
    --secondary-900: #02058D;


    --neutral-50: #F9FAFB;
    --neutral-100: #F3F4F6;
    --neutral-200: #E5E7EB;
    --neutral-300: #D1D5DB;
    --neutral-400: #9CA3AF;
    --neutral-500: #6B7280;
    --neutral-600: #4B5563;
    --neutral-700: #374151;
    --neutral-800: #1F2937;
    --neutral-900: #111827;


    --success-400: #4ADE80;

    --error-400: #EF4444;

}

body {
    color: ${p => p.theme.bodyFontColor};
    background-color: ${p => p.theme.bodyBackgroundColor};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    text-transform: lowercase;
    text-decoration: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.breadcrumb__title {
    /* font-size: 1.5rem; */
}


  .breadcrumb {
    color: ${p => p.theme.bodyParagraphColor};
    display: flex;
    text-align: left;
    
  }

  .breadcrumb__list {
    color: ${p => p.theme.bodyParagraphColor};
    display: flex;
    flex-direction: row;
    /* gap: 1rem; */
    list-style: none;
    padding: 0 !important;
    margin: 1em 0;

  }

  .breadcrumb__list__item {
    display: list-item;
    padding: 1em;
  }
  .breadcrumb__link {}
  .breadcrumb__link__active {
    color: ${p => p.theme.linkColor};
  }
  .breadcrumb__separator {
    color: ${p => p.theme.bodyParagraphColor};
    padding: 0 0.5em;
  }

`

export default GlobalStyle
