import styled from 'styled-components'

export const Button = styled.button`
  padding: 8px 16px;
  background: ${p => p.theme.primaryColor};
  color: ${p => p.theme.bodyTextColor};
  border-radius: 40px;
  border: 1px solid ${p => p.theme.bodyTextColor};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  white-space: nowrap;
  ${props => props.block ? 'display: block; width: 100%;' : ''}
  font-family:  var(--body-text-regular);
  ${props => props.disabled ? 'opacity: 0.5; cursor: not-allowed;' : ''}
  transition: all 0.3s ease-in-out;
  
  &:hover{
    background: ${p => p.theme.elevation2};);
    border: 1px solid ${p => p.theme.elevation2};
  }
`