import React from "react"
import { OutputLabel } from "@components/common/form"

function ConfirmationPage({ data }) {
  console.log(data)
  const {
    firstName,
    lastName,
    email,
    access,
    contribute,
    integrate,
    needs,
    message,
  } = data

  // const interest = interests.map(interest => {
  //   return interest.label
  // })

  // const sector = sectors.map(sector => {
  //   return sector.label
  // })

  // const contribution = contribute.map(contribution => {
  //   return contribution.label
  // })

  // // map through sectors and return labels separated by commas
  //   const sectorLabel = sector.join(", ")
  //   if(sectorLabel === "") {
  //     return <div>No sectors selected</div>
  //   }
  // fix this immediately so that it doesn't show up as undefined

  return (
    <>
      <OutputLabel label="confirm your information" />
      <OutputLabel label="full name" details={`${firstName} ${lastName}`} />
      <OutputLabel label="email" details={email} />
      {/* <OutputLabel label="interests" details={interest} /> */}

      {!access && null}
      {access && <OutputLabel label="access/focus" details={access} />}

      {!contribute && null}
      {contribute && (
        <OutputLabel label="contribution" details={contribute.label} />
      )}

      {!integrate && null}
      {integrate && (
        <OutputLabel label="integration" details={integrate.label} />
      )}

      {!needs && null}
      {needs && <OutputLabel label="needs" details={needs} />}

      {/* {!sector && <OutputLabel label="" details="" />}
      {sector && <OutputLabel label="sectors" details={sectorLabel} />} */}

      {!message && <OutputLabel label="" details="" />}
      {message && <OutputLabel label="message" details={message} />}
    </>
  )
}

export default ConfirmationPage
