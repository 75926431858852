import styled from 'styled-components';


export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 2px;
  border: none;
  background-color: ${p => p.theme.elevation2};
  box-shadow: none;
  font-family:  var(--body-text-regular);
  color: ${p => p.theme.bodyFontColor};
  height: 40px;
  


  &::placeholder{
    color: ${p => p.theme.placeholderColor};
    font-weight: normal;
    
  }

  &:hover{
   border: 1px solid ${p => p.theme.elevation8};
  }
  /* &:focus{
    background-color: ${p => p.theme.elevation2};
    color: ${p => p.theme.bodyFontColor};
    border: 2px solid ${p => p.theme.elevation2};
  } */
`