import React from "react"
import styled from "styled-components"
import {
  // ErrorMessage,
  Label,
  OutputLabel,
  TextArea,
  MultiSelect,
} from "@components/common/form"
import { FaInfo } from "react-icons/fa"

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

function Research({ data, setData }) {
  return (
    <>
      <OutputLabel
        label="contribute to research"
        details="our knowledge graph is entirely open and allows for any individual to
         contribute data, research, analysis, and review to any existing node on
         the graph. accuracy and confidence in for each contribution is natively
         encoded on the graph based contributors expertise, and consistency with
         linked contributions dependent on their confidence and accuracy. zenith
         supports both internally and externally driven research projects that
         can be closed or open sourced, as such, we provide our communities with
         opportunities to be involved in both in order to build their careers and
         monetize their contributions."
      />
      <FormWrap>
        <Label htmlFor="contribute interest">
          area(s) of interest *
          <MultiSelect
            options={[
              {
                value: "collectData",
                label: (
                  <>
                    <span style={{ paddingRight: "5px" }}>
                      collect data for our knowledge graph
                    </span>
                    <FaInfo
                      title={
                        "earn by helping us collect real data in your daily life for any specific project or our knowledge graph (in development). standard contracts are available for closed-sourced projects while data collection for our open-knowledge graph allows you to earn based on the reduction in entropy you provide. the larger the reduction the more you earn!... coming soon."
                      }
                    />
                  </>
                ),
              },
              {
                value: "publish",
                label: (
                  <>
                    <span style={{ paddingRight: "5px" }}>
                    publish & link your research
                    </span>
                    <FaInfo
                      title={
                        "use the zenith text editor for your research and assignments. With the world's most fine-grained citation encoding, your work can be natively linked to any information and source – allowing for automated source discovery and reference list / bibliography generation. Publish your work and connect your research to our knowledge graph to be discovered and referenced."
                      }
                    />
                  </>
                ),
              },
              { value: "supervise",
              label: (
                <>
                  <span style={{ paddingRight: "5px" }}>
                  supervise a research project
                  </span>
                  <FaInfo
                    title={
                      "if you are an expert you can apply to supervise a research project in your area of expertise. all project supervisors will earn remuneration as negotiated on a per project basis and are entitled to become a partner for the project."
                    }
                  />
                </>
              ), },
            ]}
            name="contribute interest"
            placeholder="How would you like to contribute?"
            isClearable={true}
            isSearchable={false}
            closeOnSelect={true}
            isMulti={false}
            // styles={customStyles}
            onChange={e => {
              setData({
                ...data,
                contribute: e,
              })
            }}
            value={data.contribute}
          />
        </Label>
        <Label htmlFor="tell us about your needs">
          tell us about your needs
          <TextArea
            name="tell us about your needs"
            placeholder="hello, "
            onChange={e => {
              setData({
                ...data,
                message: e.target.value,
              })
            }}
            value={data.message}
          />
        </Label>
      </FormWrap>
    </>
  )
}

export default Research
